












































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import formatISODuration from 'date-fns/formatISODuration'
import intervalToDuration from 'date-fns/intervalToDuration'
import OptionalEnrollment from '@/src/models/optional-enrollment'
import EnrollmentStore from '@state/modules/enrollments'
import formatDate from '@utils/format-date'
import { commit, init, terminate } from '@state/api/client/lmsClient'

type QuizState = {
  questions: {
    id: number
    label: string
    correctFeedback: string
    incorrectFeedback: string
    result: boolean | undefined
    options: {
      text: string
      value: string
      correct: boolean
      highlight: boolean
    }[]
  }[]
}

type CommitDataOptions = {
  isComplete: boolean
  shouldTerminate: boolean
}

@Component({})
export default class KnowledgeRefresherQuiz extends Vue {
  @Prop() readonly refresher!: OptionalEnrollment
  @Prop() readonly showModal: boolean = true
  initResponse: object | null = null
  currentStep = 0
  lastStep = false
  hasAnsweredCorrectly = false
  hasValidated = false
  selectedOption = null
  showErrorState = false
  showLoadingState = true
  skeletonCount = 3

  startedTime: number | null = null

  quizState: QuizState | undefined

  async mounted() {
    try {
      await this.fetchQuiz()
      await this.startEnrollment()
      await this.initEnrollment()
      this.startedTime = Date.now()
    } catch (error) {
      console.error('Error initializing knowledge refresher quiz', error)
      this.showErrorState = true
    } finally {
      this.showLoadingState = false
    }
  }

  totalCorrectAnswers(questions) {
    return questions.filter(q => q.result).length
  }

  async startEnrollment() {
    await EnrollmentStore.startEnrollment({
      enrollmentId: this.refresher.id,
      languageCode: null, // Not important for now
    })
  }

  async initEnrollment() {
    const response = await init(this.refresher.id)
    this.initResponse = response.data
  }

  async commitData(
    { isComplete, shouldTerminate }: CommitDataOptions = {
      isComplete: false,
      shouldTerminate: false,
    }
  ) {
    if (!this.initResponse) {
      // Shouldn't happen
      return
    }

    let payload: { [key: string]: any } = {
      ...this.initResponse,
      SessionTime: this.sessionTime(),
      CompletionStatus: 'in_progress',
    }
    if (isComplete) {
      payload = {
        ...payload,
        CompletionStatus: 'completed',
        SuccessStatus: 'passed',
        ScoreRaw: 1,
        ScoreScaled: 1,
        ScoreMax: 1,
      }
    }

    if (shouldTerminate) {
      await terminate(this.refresher.id, payload)
    } else {
      await commit(this.refresher.id, payload)
    }
  }

  async fetchQuiz() {
    const response = await EnrollmentStore.loadKnowledgeRefresherQuiz(
      this.refresher.id
    )
    const questions = response?.data?.knowledgeRefresherQuiz?.questions
    if (!questions || questions.length === 0) {
      throw new Error('No questions found for quiz')
    }
    this.quizState = this.buildQuizState(questions)
  }

  isLastQuestion(i) {
    if (!this.quizState) {
      return false
    }

    return this.quizState.questions.length === i + 1
  }

  async cancelKr() {
    // Don't block on this call
    this.commitData({ isComplete: false, shouldTerminate: true })
    this.$emit('close')
  }

  submitKr() {
    // The KR is already marked as complete to the API, so we just need to close the modal
    this.$emit('close')
  }

  async onNext(question) {
    // We've already validated the question
    if (this.hasValidated) {
      this.moveOnToNextQuestion()
      return
    }

    // We haven't validated the question yet
    await this.validateQuestion(question)
  }

  async moveOnToNextQuestion() {
    const originalStep = this.currentStep

    this.currentStep++
    this.selectedOption = null
    this.hasValidated = false
    this.hasAnsweredCorrectly = false
    if (this.isLastQuestion(originalStep)) {
      // We made it! (Don't block on this call)
      this.commitData({ isComplete: true, shouldTerminate: true })

      // Pop the confetti
      this.lastStep = true
    }
  }

  sessionTime() {
    return formatISODuration(
      intervalToDuration({
        start: this.startedTime!,
        end: Date.now(),
      })
    )
  }

  async validateQuestion(question) {
    const response =
      await EnrollmentStore.loadKnowledgeRefresherQuizQuestionDetails({
        enrollmentId: this.refresher.id,
        questionId: question.id,
      })

    if (!response.data.knowledgeRefresherQuiz.question) {
      // Raise the alarms
      // This shouldn't happen unless something is very wrong with the KR on the backend
      console.error('Could not resolve question on backend', {
        enrollmentId: this.refresher.id,
        questionId: question.id,
      })
      this.showErrorState = true
      return
    }

    const {
      correctOption: { id: correctOptionId },
      correctFeedback,
      incorrectFeedback,
    } = response.data.knowledgeRefresherQuiz.question

    const correctAnswer = question.options.find(
      o => o.value === correctOptionId
    )
    const selectedAnswer = question.options.find(
      o => o.value === this.selectedOption
    )

    const isCorrect = correctAnswer.value === selectedAnswer.value

    // Update the quizState
    question.correctFeedback = correctFeedback
    question.incorrectFeedback = incorrectFeedback
    question.result = isCorrect
    correctAnswer.highlight = true
    correctAnswer.correct = true
    selectedAnswer.highlight = true

    this.hasValidated = true
    this.hasAnsweredCorrectly = isCorrect

    // Don't block on this call
    this.commitData()
  }

  buildQuizState(questions) {
    if (!questions || questions.length === 0) {
      throw new Error('No questions found for quiz')
    }

    const quizState: QuizState = {
      questions: questions.map(q => ({
        id: q.id,
        label: q.label,
        // 'correctFeedback' and 'incorrectFeedback' will be set after validation
        options: q.options.map((o, index) => ({
          text: o.label,
          value: o.id,
          highlight: false,
          // 'correct' will be set after validation
        })),
      })),
    }

    return quizState
  }

  get refresherTitle(): string {
    const topics = this.refresher.storeItem?.topics || []
    return topics.map(t => this.$t(t.translationKey)).join(', ')
  }

  get enrollmentTitle(): string {
    return (
      this.refresher.storeItem.translations.find(
        t => t.language.code === this.refresher.lastUsedLanguage
      )?.title || this.refresher.storeItem.title
    )
  }

  get subtitle(): string {
    const storeItemCompletedAtDate = (this.refresher as any)
      .storeItemCompletedAtDate

    if (!storeItemCompletedAtDate) {
      return this.enrollmentTitle
    }

    const completedAtDate = formatDate(storeItemCompletedAtDate)
    return `${this.enrollmentTitle} • ${completedAtDate}`
  }

  quizIcon(question) {
    if (question.result === undefined) {
      return 'circle'
    }

    return question.result ? 'check-circle' : 'times-circle'
  }

  quizIconColor(question) {
    if (question.result === undefined) {
      return 'text-primary'
    }

    return question.result ? 'text-success' : 'text-danger'
  }

  getRandomWidth() {
    const minWidth = 50
    const maxWidth = 80
    return `${
      Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth
    }%`
  }
}
